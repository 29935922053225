import moment from 'moment';
import Site from './checkout/types/Site';

export const dateFromString = (date: string | undefined) =>
  date ? moment(date).toDate() : undefined;

export const dateToString = (date: Date | undefined | null) =>
  date ? moment(date).format('YYYY-MM-DD') : undefined;

export const getCsrfToken = () => {
  const metaDomEl = window.document.querySelector('meta[name="csrf-token"]') as HTMLMetaElement;
  return metaDomEl && metaDomEl.content;
};

export const verifyDomainForApplePay = (site: Site): Promise<Response> =>
  fetch(`/verify_apple_pay_domain/${site.id}`, {
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': getCsrfToken(),
    },
    method: 'POST',
    body: JSON.stringify({ domain: window.location.host }),
  });
